import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffectOnce } from "react-use";

import Image404 from "@/assets/images/404.png";
import Image4042x from "@/assets/images/404@2x.png";
import { buttonRow } from "@/styles/Blocks.module.scss";
import { genericErrorBox, Picture404 } from "@/styles/Error.module.scss";

export default function Error404() {
	const { push } = useRouter();
	useEffectOnce(() => {
		const redirectTimeout = setTimeout(() => {
			push("/");
		}, 5000);

		return () => {
			clearTimeout(redirectTimeout);
		};
	});

	return (
		<div className={genericErrorBox}>
			<p>Ooops... we couldn&apos;t find the page you&apos;re looking for.</p>
			<picture className={Picture404}>
				<source srcSet={`${Image404.src} 1x, ${Image4042x.src} 2x`} />
				<Image src={Image404} alt="404 graphics" />
			</picture>
			<h1>Page not found</h1>
			<div className={buttonRow}>
				<Link href="/" className="button">
					Back to Homepage
				</Link>
			</div>
		</div>
	);
}

Error404.breadcrumbs = false;
Error404.cookieConsent = false;
